import React from 'react';
import { NotFoundPageContainer } from '@containers';
import { useSiteSettingsData } from '@hooks';

const NotFoundPage = () => {
  const siteSettingsData = useSiteSettingsData();

  return (
    <NotFoundPageContainer {...siteSettingsData} />
  );
};

export default NotFoundPage;